export const menuItems = [
  { href: "program", label: "Программа" },
  { href: "team", label: "Преподаватели" },
  { href: "prices", label: "Стоимость" },
  { href: "licence", label: "Образовательная лицензия" },
]

export interface TeamUnit {
  name: string
  description: string
  value: string
  cover: string
  small: string
  texts: Array<string>
  links?: {
    vk?: string
    instagram?: string
    telegram?: string
  }
}

export const team: Array<TeamUnit> = [
  {
    name: "Александр Бурлаков",
    description: "Врач-диетолог доказательной медицины. Тренер - преподаватель. Научный журналист",
    value: "burlakov",
    cover: "assets/team/burlakov/burlakov-480.jpg",
    small: "assets/team/burlakov/burlakov-64.jpg",
    texts: [
      "Опыт работы более 9 лет",
      "Специалист по диетологическому сопровождению спортсменов UFC, ACA, RCC",
      "Автор книги: \"В гармонии с едой\"",
      "Приглашенный эксперт для проверки постерных докладов студентов и молодых ученых, медицинских конференций (РНИМУ им. Пирогова)",
      "Сертификационные курсы: разработка и интерпретация клинических исследований (Johns Hopkins University), организация хранения и обработки данных для клинических исследований (Vanderbilt University), эпидемиология: фундаментальная наука о здоровье населения (University of North Carolina at Chapel Hill)",
      "Обучил более 5000 студентов",
    ],
    links: {
      vk: "https://vk.com/dr.burlakov",
      instagram: "https://instagram.com/dr.burlakov",
    },
  },
  {
    name: "Станислав Хан",
    description: "Врач-эндокринолог, диетолог",
    value: "khan",
    cover: "assets/team/khan/khan-480.jpg",
    small: "assets/team/khan/khan-64.jpg",
    texts: [
      "Автор книги \"Страсти по щитовидке\"",
      "2011 г. -2017 г. – Первый МГМУ им.И.М.Сеченова, Лечебное дело. Красный диплом, медаль \"Преуспевшему\"",
      "2017 г. - 2019 г. – Ординатура в ФГБУ НМИЦ Эндокринологии Минздрава России",
      "2019 г. – Первичная специализация по программе \"Диетология\". Центр непрерывного образования, г. Москва",
    ],
    links: {
      instagram: "https://instagram.com/doctor__khan",
    },
  },
  {
    name: "Ольга Косникова",
    description: "Пищевой технолог, популяризатор науки",
    value: "kosnikova",
    cover: "assets/team/kosnikova/kosnikova-480.jpg",
    small: "assets/team/kosnikova/kosnikova-64.jpg",
    texts: [
      "Автор книги \"Страшная химия: Еда с Е-шками\"",
      "Окончила главный пищевой вуз страны МГУПП по специальности \"Инженер-технолог субтропических производств\"",
      "Консультант ТВ-каналов (НТВ, Москва 24, ТВЦ). Вела авторскую передачу на ТВ \"Еда\"",
      "Участник форума \"Учёные против мифов-14\"",
      "11 лет работы в пищевой индустрии",
    ],
    links: {
      instagram: "https://instagram.com/chemistry_by_olga",
    },
  },
  {
    name: "Даниэла Пургина",
    description: "Взрослый и детский гастроэнтеролог, диетолог",
    value: "purgina",
    cover: "assets/team/purgina/purgina-480.jpg",
    small: "assets/team/purgina/purgina-64.jpg",
    texts: [
      "Аспирант кафедры эпидемиологии инфекционных и неинфекционных заболеваний ФБУН \"НИИ эпидемиологии и микробиологии имени Пастера\"",
      "Диагностика и лечение широкого спектра заболеваний желудочно-кишечного тракта у детей и взрослых",
      "Сертифицированный специалист в области когнитивно-поведенческой психотерапии",
    ],
    links: {
      instagram: "https://instagram.com/doctor.purgina",
    },
  },
  {
    name: "Варвара Веретюк",
    description: "Врач-терапевт",
    value: "veretuk",
    cover: "assets/team/veretuk/veretuk-480.jpg",
    small: "assets/team/veretuk/veretuk-64.jpg",
    texts: [
      "Кафедра неотложной терапии с эндокринологией и профпатологией ФПК и ППВ ФГБОУ ВО НГМУ",
      "Преподаватель: ассистент кафедры неотложной терапии с эндокринологией и профпатологией ФПК и ППВ ФГБОУ ВО Новосибирского государственного медицинского университета",
      "Автор просветительских и научный статей и профессионального блога",
    ],
    links: {
      instagram: "https://instagram.com/varvara_veretyuk",
    },
  },
  {
    name: "Алексей Варфоломеев",
    description: "Специалист по модификации образа жизни",
    value: "varfolomeev",
    cover: "assets/team/varfolomeev/varfolomeev-480.jpg",
    small: "assets/team/varfolomeev/varfolomeev-64.jpg",
    texts: [
      "Нутрициолог",
      "Судья международного класса по вейкборду IWWF/CWWC",
    ],
    links: {
      instagram: "https://instagram.com/varfolomeev.fit",
    },
  },
  {
    name: "Денис Лебедев",
    description: "Врач-эндокринолог, специалист по клиническим исследованиям",
    value: "lebedev",
    cover: "assets/team/lebedev/lebedev-480.jpg",
    small: "assets/team/lebedev/lebedev-64.jpg",
    texts: [
      "Выпускник медицинского факультета СПБГУ, ординатуры и аспирантуры по эндокринологии Национального исследовательского центра им. В.А.Алмазова",
      "Автор более 10 полнотекстовых статей, в том числе, в рейтинговых зарубежных журналах",
    ],
    links: {
      instagram: "https://instagram.com/true_endo",
    },
  },
  {
    name: "Андрей Харитонов",
    description: "Кандидат медицинских наук, гастроэнтеролог",
    value: "kharitonov",
    cover: "assets/team/kharitonov/kharitonov-480.jpg",
    small: "assets/team/kharitonov/kharitonov-64.jpg",
    texts: [
      "Неоднократно участвовал и выступал с докладами на российских и международных научных форумах",
      "2011 — 2016 гг. — преподаватель кафедры гастроэнтерологии и диетологии СЗГМУ имени И. И. Мечникова",
      "Диетотерапия и нутриционная поддержка при воспалительных заболеваниях кишечника (болезнь Крона и язвенный колит): учебное пособие",
      "Книга: \"Эффективная диета при вздутии и боли в животе\" авторы А.Харитонов, И.Волкова",
      "Автор книги: \"Болезнь Крона и язвенный колит: рекомендации по образу жизни\"",
    ],
    links: {
      instagram: "https://instagram.com/kharitonov_ag",
    },
  },
  {
    name: "Татьяна Денисова",
    description: "Врач-эндокринолог, диетолог, клинический психолог",
    value: "denisova",
    cover: "assets/team/denisova/denisova-480.jpg",
    small: "assets/team/denisova/denisova-64.jpg",
    texts: [
      "Окончила Первый Московский государственный Медицинский университет им. И. М. Сеченова  по специальности лечебное дело",
      "Проходила обучение  в  клинической ординатуре по специальности Эндокринология в Эндокринологическом Научном центре",
      "Профессиональная переподготовка по диетологии (РУДН) и клинической психологии( Институт Организационной психологии)",
      "Специализация на диетологическом консультировании пациентов с метаболическими нарушениями, избыточной массой тела, ожирением,  РПП, девушек/женщин с ФГА на фоне спорта, диет и стресса",
      "Спикер образовательной онлайн-платформы Meducation. Обучающие лекции,  вебинары для врачей, пациентов,  посвящённые эндокринным нарушениям, а также питанию в норме и  при различных патологиях",
      "Стаж работы более 13 лет",
    ],
    links: {
      instagram: "https://instagram.com/doctor_denisova_",
    },
  },
  {
    name: "Анастасия Левитская",
    description: "Врач-гастроэнтеролог",
    value: "levitskaya",
    cover: "assets/team/levitskaya/levitskaya-480.jpg",
    small: "assets/team/levitskaya/levitskaya-64.jpg",
    texts: [
      "Московский областной научно-исследовательский клинический институт им. М. Ф. Владимирского (ГБУЗ МО МОНИКИ им. М. Ф. Владимирского)",
      "Аспирантура по специальности Гастроэнтерология",
      "Участие (в том числе выступления) в профессиональных медицинских конференциях, написание статей, публикации ECCO, EUG и др.",
      "Работа с ординаторами и врачами первичного звена на кафедре гастроэнтерологии ФУВ МОНИКИ",
      "Работа в качестве эксперта курса DOCMASCHOOL \"Гастроэнтерология, которая работает\", \"Терапия\"",
    ],
    links: {
      instagram: "https://instagram.com/dr.levitskaya.gastro",
    },
  },
  {
    name: "Елена Выставкина",
    description: "Врач-ревматолог. Кандидат медицинских наук",
    value: "vistavkina",
    cover: "assets/team/vistavkina/vistavkina-480.jpg",
    small: "assets/team/vistavkina/vistavkina-64.jpg",
    texts: [
      "Автор трёх книг \"Ревматология по косточкам\", \"Истории из соседней палаты\" и \"Ревматология по полочкам - для пациентов и не только\"",
      "Окончила РостГМУ в 2005 году",
      "Первичная специализация по ревматологии, НИИ ревматологии, г. Москва, 2006 год",
    ],
    links: {
      instagram: "https://www.instagram.com/dr_vystavkina",
    },
  },
  {
    name: "Анна Берекетова",
    description: "Нутрициолог",
    value: "berekretova",
    cover: "assets/team/berekretova/berekretova-480.jpg",
    small: "assets/team/berekretova/berekretova-64.jpg",
    texts: [
      "Специалист по питанию и пищевому поведению",
      "Преподаватель и методист учебного центра Body Coach",
      "Ассистент врача-диетолога Александра Бурлакова",
    ],
    links: {
      instagram: "https://instagram.com/anutaber_nutri_s",
    },
  },
  {
    name: "Валерия Федоровских",
    description: "Врач-педиатр",
    value: "fedorovskih",
    cover: "assets/team/fedorovskih/fedorovskih-480.jpg",
    small: "assets/team/fedorovskih/fedorovskih-64.jpg",
    texts: [
      "Закончила педиатрический факультет ТюмГМУ с отличием",
      "Закончила ординатуру по специальности \"Педиатрия\"",
      "Преподаватель биологической химии студентам медицинских ВУЗов",
    ],
    links: {
      instagram: "https://instagram.com/valeruna_",
    },
  },
  {
    name: "Иван Кузнецов",
    description: "Врач-педиатр",
    value: "kuznetsov",
    cover: "assets/team/kuznetsov/kuznetsov-480.jpg",
    small: "assets/team/kuznetsov/kuznetsov-64.jpg",
    texts: [
      "Закончил педиатрический факультет ТюмГМУ с отличием",
      "Закончил ординатуру по специальности \"Педиатрия\"",
      "2 года работал в \"красной зоне\" стационара",
      "Преподаватель патологической физиологии студентам медицинских ВУЗов",
    ],
    links: {
      instagram: "https://instagram.com/doc.spartakovich",
    },
  },
  {
    name: "Кадира Атланова",
    description: "Нутрициолог",
    value: "atlanova",
    cover: "assets/team/atlanova/atlanova-480.jpg",
    small: "assets/team/atlanova/atlanova-64.jpg",
    texts: [
      "Health-coach",
      "Cпециалист по питанию и здоровому образу жизни",
    ],
    links: {
      instagram: "https://instagram.com/ka.di.ra",
    },
  },
  {
    name: "Виктория Вишнякова",
    description: "Нутрициолог",
    value: "vishnyakova",
    cover: "assets/team/vishnyakova/vishnyakova-480.jpg",
    small: "assets/team/vishnyakova/vishnyakova-64.jpg",
    texts: [
      "Член РОСНДП",
      "UNICEF - специалист по питанию детей",
      "Медицинский журналист",
    ],
    links: {
      instagram: "https://instagram.com/pronutr_com",
    },
  },
  {
    name: "Владислав Николайко",
    description: "Фитнес-эксперт",
    value: "nikolaiko",
    cover: "assets/team/nikolaiko/nikolaiko-480.jpg",
    small: "assets/team/nikolaiko/nikolaiko-64.jpg",
    texts: [
      "Мастер спорта по бодибилдингу и пауэрлифтингу",
      "Действующий судья Федерации бодибилдинга России",
    ],
    links: {
      instagram: "https://instagram.com/i_am_vlad_nikolaiko",
    },
  },
  {
    name: "Валентина Салова",
    description: "Провизор",
    value: "salova",
    cover: "assets/team/salova/salova-480.jpg",
    small: "assets/team/salova/salova-64.jpg",
    texts: [
      "Медицинский писатель в области клинических исследований",
      "Автор блога о лекарствах",
    ],
    links: {
      instagram: "https://instagram.com/pharm_mix",
    },
  },
  {
    name: "Елизавета Редько",
    description: "Врач-педиатр",
    value: "redko",
    cover: "assets/team/redko/redko-480.jpg",
    small: "assets/team/redko/redko-64.jpg",
    texts: [
      "Аллерголог, иммунолог",
      "Главный врач клиники Фомина в г. Краснодар",
      "Организатор врачебного общества \"Лига доказательной медицины\"",
      "Вдохновитель и организатор форумов MedFest",
    ],
    links: {
      instagram: "https://instagram.com/dr.elizavetaredko",
    },
  },
  {
    name: "Екатерина Карпушина",
    description: "Врач-дерматолог, косметолог, трихолог",
    value: "karpushina",
    cover: "assets/team/karpushina/karpushina-480.jpg",
    small: "assets/team/karpushina/karpushina-64.jpg",
    texts: [
      "Член Европейской Академии Дерматологии и Венерологии",
      "Член Международного общества дерматоскопии",
      "Член Московского общества дерматовенерологов и косметологов имени А.И. Поспелова",
    ],
    links: {
      instagram: "https://instagram.com/dr.karpushina",
    },
  },
  {
    name: "Анна Коновалова",
    description: "Врач-эндокринолог, диетолог",
    value: "konovalova",
    cover: "assets/team/konovalova/konovalova-480.jpg",
    small: "assets/team/konovalova/konovalova-64.jpg",
    texts: [
      "Член Российской ассоциации эндокринологов",
      "Член Национальной ассоциации клинического питания",
      "Член Российского союза нутрициологов, диетологов и специалистов пищевой индустрии",
      "Член Ассоциации гинекологов-эндокринологов России",
      "Член Российской ассоциации по остеопорозу",
    ],
    links: {
      instagram: "https://instagram.com/dr.anna_konovalova",
    },
  },
  {
    name: "Анастасия Егорова",
    description: "Преподаватель нутрициологии и психологии РПП",
    value: "egorova",
    cover: "assets/team/egorova/egorova-480.jpg",
    small: "assets/team/egorova/egorova-64.jpg",
    texts: [
      "Специалист по коррекции веса",
      "Специалист по лечебному питанию",
      "Специалист по работе с пищевым поведением",
      "Научный редактор книг по питанию и расстройствам пищевого поведения",
      "Автор книги  \"Есть хорошо! Без голодовок, запретов и стрессов\"",
    ],
    links: {
      instagram: "https://instagram.com/egorova_dietolog",
    },
  },
  {
    name: "Даниил Дьяченко",
    description: "Выпускник КГУ ФКСТ",
    value: "dyachenko",
    cover: "assets/team/diyachenko/diyachenko-480.jpg",
    small: "assets/team/diyachenko/diyachenko-64.jpg",
    texts: [
      "Mac-Nutrition University (Martin McDonald)",
      "Мастер спорта по становой тяге AWPC",
      "Создатель проекта \"My_revolution\"",
    ],
    links: {
      instagram: "https://instagram.com/danildyachenko",
    },
  },
  {
    name: "Ольга Кравчук",
    description: "Врач-эндокринолог",
    value: "kravchuk",
    cover: "assets/team/kravchuk/kravchuk-480.jpg",
    small: "assets/team/kravchuk/kravchuk-64.jpg",
    texts: [
      "В 2014 г. году получила высшее медицинское образование по специальности лечебное дело",
      "С 2014 г. опыт работы в государственных медико-профилактических учреждений (стационар, амбулаторно-поликлиническая помощь, частная врачебная практика)",
      "В 2016 г. закончила ординатуру по специальности Эндокринология в военно-медицинской академии им. С.М. Кирова",
      "С 2020 г. сотрудник команды Physical Transformation - эксперт по контенту и консультант команды по медицинским вопросам",
      "Основная актуальная область интересов: медицинское консультирование людей, занимающихся спортом",
    ],
    links: {
      instagram: "https://instagram.com/meat_and_doc",
    },
  },
  {
    name: "Татьяна Голубева",
    description: "Кандидат биологических наук, старший научный сотрудник",
    value: "golubeva",
    cover: "assets/team/golubeva/golubeva-480.jpg",
    small: "assets/team/golubeva/golubeva-64.jpg",
    texts: [
      "2007 г. - 2012 г. - Новосибирский государственный университет, факультет естественных наук, биологическое отделение, специалист",
      "2017 г. - защита диссертации на соискание ученой степени кандидата биологических наук (клеточная биология, цитология, гистология)",
      "Автор более 50 статей в научных журналах",
      "Старший преподаватель СУНЦ НГУ",
    ],
    links: {
      instagram: "https://instagram.com/zhazhachka",
    },
  },
  {
    name: "Анастасия Юрпалова",
    description: "Нутрициолог, фитнес-тренер",
    value: "urpalova",
    cover: "assets/team/urpalova/urpalova-480.jpg",
    small: "assets/team/urpalova/urpalova-64.jpg",
    texts: [
      "Курс Лайна Нортона \"The Science of Nutrition\", Clean Health Fitness Institute",
      "\"Fat Loss Fundamentals\", Clean Health Fitness Institute",
      "\"Managing Your Health: The Role of Physical Therapy and Exercise\", University of Toronto",
      "\"Stanford Introduction to Food and Health\", Stanford University",
      "\"Cooking for Busy Healthy People\", Stanford University",
      "Rebuilding Our Relationship with Food\", Stanford University",
      "Куратор образовательно-тренировочных курсов Дмитрия Путылина Хардкор, Хардкор-Реверс, Форма",
      "Сотрудничала в качестве эксперта-редактора разделов Фитнес, Здоровье и Еда, с журналом Men Today",
      "Автор серии статей о фитнес индустрии для Men Today",
    ],
    links: {
      instagram: "https://instagram.com/nastya.yurp",
    },
  },
  {
    name: "Джесси Хмеляр",
    description: "Наставник врачей в соц. сетях, врач-гастроэнтеролог",
    value: "khmelyar",
    cover: "assets/team/khmelyar/khmelyar-480.jpg",
    small: "assets/team/khmelyar/khmelyar-64.jpg",
    texts: [
      "Спикер курсов DocMaSchool",
      "Сертификат Good clinical practice (GCP)",
      "2016 - \"Лечебное дело\", Балтийский федеральный университет имени Иммануила Канта",
      "2017 - Интернатура по специальности \"Терапия\", Балтийский федеральный университет имени Иммануила Канта",
      "2018 - Профессиональная переподготовка \"Общая врачебная практика (семейная медицина)\", Северо-Западный государственный медицинский университет им. И. И. Мечникова",
      "2018 - Профессиональная переподготовка \"Гастроэнтерология\", Северо-Западный государственный медицинский университет им. И. И. Мечникова",
    ],
    links: {
      instagram: "https://instagram.com/doctor__jesse",
    },
  },
  {
    name: "Раиса Комиссарова",
    description: "Нутрициолог, ЗОЖ-коуч, специалист по психологической коррекции пищевого поведения и привычек",
    value: "komissarova",
    cover: "assets/team/komissarova/komissarova-480.jpg",
    small: "assets/team/komissarova/komissarova-64.jpg",
    texts: [
      "Курс повышения квалификации “Детское питание и пищевое поведение” (Научно-образовательный центр современных медицинских технологий)",
      "Курс повышения квалификации “Терапия принятия и ответственности в работе с РПП” (Институт дополнительного профессионального образования)",
      "Курс повышения квалификации “Терапия, сфокусированная на сострадании, в работе с РПП” (Институт дополнительного профессионального образования)",
      "Обучающий курс “Когнитивно-поведенческая терапия ожирения” (Ассоциация Когнитивно-поведенческой Психотерапии)",
      "Профессиональная переподготовка по программе “Консультант по коррекции пищевого поведения” (Школа консультантов Евгении Меглинской)",
    ],
    links: {
      instagram: "https://instagram.com/komissar_zozh",
    },
  },
  {
    name: "Алексей Водовозов",
    description: "Врач (первичная специализация - терапия, вторичная - токсикология), научный журналист",
    value: "vodovozov",
    cover: "assets/team/vodovozov/vodovozov-480.jpg",
    small: "assets/team/vodovozov/vodovozov-64.jpg",
    texts: [
      "Автор книги \"Пациент разумный\"",
      "Член Ассоциации медицинских журналистов",
      "Призёр премии \"Russian Sci&Tech Writer of the Year\", 2019",
      "Финалист премии \"Радиомания\", 2023",
    ],
    links: {
      telegram: "https://t.me/dr_voenvrach",
    },
  },
  {
    name: "Андрей Симаков",
    description: "Врач-гастроэнтеролог, Врач УЗ-диагностики",
    value: "simakov",
    cover: "assets/team/simakov/simakov-480.jpg",
    small: "assets/team/simakov/simakov-64.jpg",
    texts: [
      "Специалист в области нейрогастроэнтерологии и функциональных расстройств ЖКТ",
    ],
    links: {
      instagram: "https://www.instagram.com/simakov_hastro_doc?igsh=b20zZTQ3bHdsdXll",
      telegram: "https://t.me/drsimakov",
    },
  },
  {
    name: "Амина Мирсакиева",
    description: "Физик, химик, научный популяризатор",
    value: "mirsakieva",
    small: "assets/team/mirsakieva/mirsakieva-small.jpg",
    cover: "assets/team/mirsakieva/mirsakieva.jpg",
    texts: [
      "Магистр и бакалавр химического инжиниринга (КБТУ, Казахстан)",
      "PhD Material Physics (КТН, Швеция, 2017)",
      "100 новых лиц Казахстана 2021",
      "Основательница сообщества женщин-ученых из Казахстана",
    ],
    links: {
      telegram: "https://t.me/belkastrelkapodcast",
      instagram: "https://www.instagram.com/dr_amina_mir",
    },
  },
  {
    name: "Рамзан Чемурзиев",
    description: "Фитнес наставник, нутрициолог, health-coach",
    value: "chemurziev",
    small: "assets/team/chemurziev/chemurziev-64.jpg",
    cover: "assets/team/chemurziev/chemurziev-480.jpg",
    texts: [
      "Фитнес наставник",
      "Нутрициолог",
      "Health-coach",
    ],
    links: {
      telegram: "https://t.me/healthcoach_ramzan",
      instagram: "https://www.instagram.com/ramzan.healthcoach",
    },
  },
]
